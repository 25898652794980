<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> | 
    <router-link to="/ads">Ads</router-link> | 
    <a href="/other.html">Non-SDK</a>
  </nav>
  <button @click="clear">Clear Data</button>
  <router-view/>
  <div>currentRoute</div>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {useRouter} from 'vue-router';

let router = useRouter();

function clear(){
  localStorage.clear();
  sessionStorage.clear();
  document.cookie = "";
  alert("Data Cleared");
}

let route = computed(() => router.currentRoute.value.path);

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
